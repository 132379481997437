import React from 'react';
import Footer from '../Footer/Footer';
import Cards2 from '../Cards/Cards2';
import ContactBande from '../Contact/ContactBande';

function QuiSuisJe() {
    return (
        <>
            <Cards2 />
            <ContactBande />
            <Footer />
        </>
    )
};


export default QuiSuisJe;