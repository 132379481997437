import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-logo'>
                <a href="/" className='navbar-logo'>
                    <div>
                        <h5>Christine Preljocaj</h5>
                        <p className='home'>
                            PSYCHOLOGUE CLINICIENNE
                        </p>
                    </div>
                </a>
            </div>
            <div className='footer-link-wrapper'>
                <div class='footer-link-items'>
                    <h2>Adresse</h2>
                    <p className="adresse">
                        1bis rue des Fontaines, 94370 Sucy-en-Brie.
                    </p>
                </div>
                <div class='footer-link-items'>
                    <h2>Contact</h2>
                    <a className='website-rights' href="mailto:christine.preljo@gmail.com">Par E-mail</a>
                </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <small className='website-rights'>
                        Christine Preljocaj © 2023 - by <a href="mailto:robert.kertis@gmail.com">Robert Kertis</a>
                    </small>

                    <div className='social-icons'>
                        <Link
                            className='social-icon-link facebook'
                            to='https://www.facebook.com/p/Danse-Psy-Th%C3%A9rapie-100076482304380/'
                            target='_blank'
                            aria-label='Facebook'
                        >
                            <i className='fab fa-facebook-f' />
                        </Link>
                        <Link
                            className='social-icon-link twitter'
                            to='https://www.linkedin.com/in/christine-preljocaj-87439a49/'
                            target='_blank'
                            aria-label='LinkedIn'
                        >
                            <i className='fab fa-linkedin' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;