import React from 'react';
import './Bande.css';
import '../Button/Button.scss'

const ContactBande = () => {
    return (
        <>
            <div className="bande">
                <h1>Débutez votre accompagnement dès maintenant
                    <form action="https://calendly.com/christine-preljocaj">
                        <button type="submit" class="btn btn--yellow">
                            <span class="btn__txt">Prendre un RDV</span>
                            <i class="btn__bg" aria-hidden="true"></i>
                            <i class="btn__bg" aria-hidden="true"></i>
                            <i class="btn__bg" aria-hidden="true"></i>
                            <i class="btn__bg" aria-hidden="true"></i>
                        </button>
                    </form></h1>
            </div>

        </>
    );
};

export default ContactBande;