import React from 'react';
import '../../App.css';
import './HeroSection.css';

const HeroSection = () => {
    return (
        <>
            <img src='/images/cabinet.avif' alt='Image accueil' />

            <div className='hero-container reveal'>
                <span className="disp-none">Paris</span> <span className="disp-none">île-de-france</span> <span className="disp-none">val-de-marne</span>
                <span className="disp-none">psychologue</span> <span className="disp-none">sucy-en-brie</span> <span className="disp-none">therapeute</span>
                <h1 className='reveal-1'>
                    CHRISTINE PRELJOCAJ
                </h1>
                <p className='reveal-Y-6'>
                    Psychologue Clinicienne <br /> Psychothérapeute EMDR
                </p>
                <p className='reveal-Y-5'>
                    Danse Thérapeute
                </p>
            </div>
        </>
    );
};

export default HeroSection;