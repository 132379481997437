import React from 'react';
import './Tarif.css';

const Tarif = () => {
    return (
        <>
            <div className="reveal">
                <div className="title reveal-1'">
                    <h1>En Cabinet</h1>
                </div>
                <div className="tarif-cabinet reveal-1">

                    <p className='cabinet coach reveal-2'>Thérapie accompagnement individuel</p>
                    <p className="price-1 reveal-2">
                        70€
                    </p>
                    <p className='cabinet coach reveal-3'>
                        Thérapie EMDR
                    </p>
                    <p className="price-2 pad reveal-3">
                        140€
                    </p>
                    <p className="cabinet coach reveal-4">Coaching</p>
                    <p className="price-3 pad reveal-4">
                        Sur devis
                    </p>
                    <p className="cabinet coach reveal-5">
                        Danse Thérapie en groupe 2H (min 6 - max 10 pers.)
                    </p>
                    <p className="price-4 pad reveal-5">
                        40€/pers.
                    </p>
                </div>
                <div className="title reveal-1'">
                    <h1 className='reveal-6'>En Visioconférence</h1>
                </div>
                <div className="tarif-cabinet reveal-6">
                    <p className="cabinet coach reveal-7">
                        Thérapie accompagnement individuel
                    </p>
                    <p className="price-5 reveal-7">
                        70€
                    </p>
                </div>
                <p className="secu reveal-7">Tarifs affichés par séance.</p>
                <p className="secu reveal-7 bold font">Règlement en espèces ou par virement bancaire.</p>
                <p className='bot reveal-8'>Les séances ne sont pas remboursées par la sécurité sociale. <br />
                    Cependant, certaines mutuelles peuvent les prendre en charge.</p>
            </div>
        </>
    );
};

export default Tarif;